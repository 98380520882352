
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93c5pobhngAjMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/[...slug].vue?macro=true";
import { default as autologinou5cVUtQaXMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/auth/autologin.vue?macro=true";
import { default as callbackhWYZ7KS7OkMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/auth/social/[connection]/callback.vue?macro=true";
import { default as bettingmyne0lMMu7Meta } from "/builds/platform/customer-frontends/demo/skeleton/pages/betting.vue?macro=true";
import { default as _91pageIdentity_93QvKnAz6EUoMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/bonus/[pageIdentity].vue?macro=true";
import { default as _91categoryIdentity_93T5p1BHp3CaMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/categories/[categoryIdentity].vue?macro=true";
import { default as categories1v9XJKsdK4Meta } from "/builds/platform/customer-frontends/demo/skeleton/pages/categories.vue?macro=true";
import { default as contacth1mAKcKEerMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/contact.vue?macro=true";
import { default as favorites_46clientHzQi11P2yDMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/favorites.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/platform/customer-frontends/demo/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91id_93mcclBSWysDMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/games/[id].vue?macro=true";
import { default as indexnmUy2AiC66Meta } from "/builds/platform/customer-frontends/demo/skeleton/pages/index.vue?macro=true";
import { default as loyaltyxxErgaVcqEMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/loyalty.vue?macro=true";
import { default as mainz7RgUbbp2VMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/main.vue?macro=true";
import { default as bonusesll0Sf7onrWMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/profile/bonuses.vue?macro=true";
import { default as documentsd2Is4GSMVSMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/profile/documents.vue?macro=true";
import { default as historyifN3Kj0mljMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/profile/history.vue?macro=true";
import { default as infovFbT1oftCeMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/profile/info.vue?macro=true";
import { default as limitsVN6RI6Kv84Meta } from "/builds/platform/customer-frontends/demo/skeleton/pages/profile/limits.vue?macro=true";
import { default as notificationsv4UjyM2Ei0Meta } from "/builds/platform/customer-frontends/demo/skeleton/pages/profile/notifications.vue?macro=true";
import { default as securityiAk4yWbrYbMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/profile/security.vue?macro=true";
import { default as verificationfOpNqxykycMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/profile/verification.vue?macro=true";
import { default as walletsFIa5xOYcaMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/profile/wallet.vue?macro=true";
import { default as profile_46clientNY04cedgzCMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/profile.client.vue?macro=true";
import { default as providerscDa2SPyyN5Meta } from "/builds/platform/customer-frontends/demo/skeleton/pages/providers.vue?macro=true";
import { default as _91pageIdentity_93lvOzSYnnVDMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/questions/[pageIdentity].vue?macro=true";
import { default as questionsB7rkeOwIgSMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/questions.vue?macro=true";
import { default as recently_45played_46clientB4N36YteH0Meta } from "/builds/platform/customer-frontends/demo/skeleton/pages/recently-played.client.vue?macro=true";
import { default as _91pageIdentity_931BZuNw5h9YMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/static/[pageIdentity].vue?macro=true";
import { default as _91identity_93106Hs1toYUMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/tournaments/[identity].vue?macro=true";
import { default as indexwUYf3HImx5Meta } from "/builds/platform/customer-frontends/demo/skeleton/pages/tournaments/index.vue?macro=true";
import { default as welcome_45packageLDaAHImhGiMeta } from "/builds/platform/customer-frontends/demo/skeleton/pages/welcome-package.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/[...slug].vue")
  },
  {
    name: "auth-autologin",
    path: "/auth/autologin",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/auth/autologin.vue")
  },
  {
    name: "auth-social-connection-callback",
    path: "/auth/social/:connection()/callback",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/auth/social/[connection]/callback.vue")
  },
  {
    name: "betting",
    path: "/betting",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/betting.vue")
  },
  {
    name: "bonus-pageIdentity",
    path: "/bonus/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/bonus/[pageIdentity].vue")
  },
  {
    name: "categories",
    path: "/categories",
    meta: categories1v9XJKsdK4Meta || {},
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/categories.vue"),
    children: [
  {
    name: "categories-categoryIdentity",
    path: ":categoryIdentity()",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/categories/[categoryIdentity].vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/contact.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/demo/skeleton/pages/favorites.client.vue"))
  },
  {
    name: "games-id",
    path: "/games/:id()",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/games/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/index.vue")
  },
  {
    name: "loyalty",
    path: "/loyalty",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/loyalty.vue")
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/main.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/demo/skeleton/pages/profile.client.vue")),
    children: [
  {
    name: "profile-bonuses",
    path: "bonuses",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/profile/bonuses.vue")
  },
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/profile/documents.vue")
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/profile/history.vue")
  },
  {
    name: "profile-info",
    path: "info",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/profile/info.vue")
  },
  {
    name: "profile-limits",
    path: "limits",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/profile/limits.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/profile/notifications.vue")
  },
  {
    name: "profile-security",
    path: "security",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/profile/security.vue")
  },
  {
    name: "profile-verification",
    path: "verification",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/profile/verification.vue")
  },
  {
    name: "profile-wallet",
    path: "wallet",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/profile/wallet.vue")
  }
]
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/providers.vue")
  },
  {
    name: "questions",
    path: "/questions",
    meta: questionsB7rkeOwIgSMeta || {},
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/questions.vue"),
    children: [
  {
    name: "questions-pageIdentity",
    path: ":pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/questions/[pageIdentity].vue")
  }
]
  },
  {
    name: "recently-played",
    path: "/recently-played",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/demo/skeleton/pages/recently-played.client.vue"))
  },
  {
    name: "static-pageIdentity",
    path: "/static/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/static/[pageIdentity].vue")
  },
  {
    name: "tournaments-identity",
    path: "/tournaments/:identity()",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/tournaments/[identity].vue")
  },
  {
    name: "tournaments",
    path: "/tournaments",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/tournaments/index.vue")
  },
  {
    name: "welcome-package",
    path: "/welcome-package",
    component: () => import("/builds/platform/customer-frontends/demo/skeleton/pages/welcome-package.vue")
  }
]