import validate from "/builds/platform/customer-frontends/demo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45redirect_45global from "/builds/platform/customer-frontends/demo/skeleton/middleware/01.redirect.global.ts";
import _02_45session_45global from "/builds/platform/customer-frontends/demo/skeleton/middleware/02.session.global.ts";
import route_45global from "/builds/platform/customer-frontends/demo/skeleton/middleware/route.global.ts";
import manifest_45route_45rule from "/builds/platform/customer-frontends/demo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45redirect_45global,
  _02_45session_45global,
  route_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/platform/customer-frontends/demo/skeleton/middleware/auth.ts")
}